import { Reducer } from 'redux'
import { fromJS } from 'immutable'
import { IPersist } from './../models'
import * as constants from 'redux-persist/lib/constants'

export const initialState: IPersist = fromJS({
  rehidrated: false,
})

const persistReducer: Reducer<IPersist> = (state = initialState, action) => {
  switch (action.type) {
    case constants.REHYDRATE:
      return state.set('rehidrated', true)
    default:
      return state
  }
}

export default persistReducer
