import React, { FC } from 'react'
import { BrowserRouter } from 'react-router-dom'
import MainRouter from './Main'

const Router: FC = () => (
  <BrowserRouter>
    <MainRouter />
  </BrowserRouter>
)
export default Router
