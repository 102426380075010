import { Reducer } from 'redux'
import { fromJS } from 'immutable'
import { IUser } from './../models'
import * as TYPES from './../actionTypes'

export const initialState: IUser = fromJS({
  uuid: '',
})

const userReducer: Reducer<IUser> = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.LOGIN.TRIGGER:
      return state.set('loading', true)

    case TYPES.LOGIN.SUCCESS:
      return state.merge(action.payload)

    case TYPES.LOGIN.FAILURE:
      return state.set('error', action.payload)

    case TYPES.LOGIN.FULFILL:
      return state.set('loading', false)

    case TYPES.SET:
      return state.merge(action.payload)

    case TYPES.LOGOUT:
      return initialState
    default:
      return state
  }
}

export default userReducer
