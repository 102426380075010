import styled from 'styled-components'

export const ContainerHome = styled.div`
  width: 98vw;
  max-width: 1440px;
  margin: auto;
  background-color: #e5e5e5;
`

export const Header = styled.header`
  font-family: MontserratMedium;
  height: auto;
  width: 100%;
  margin: auto;
  top: 0;
  background: #ffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  .logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50vw;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -1px;
    color: #1c387e;
    margin: auto;
    cursor: pointer;
  }
  .logoCompany {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50vw;
    margin: auto;
  }
`
interface iphone {
  image?: string
}
export const Phone = styled.div`
  width: auto;
  height: 866px;
  background-image: ${(p: iphone) => (p.image ? `url(${p.image})` : '')};
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 566px auto;
  @media screen and (max-width: 1100px) {
    background-size: 466px auto;
  }
  @media screen and (max-width: 900px) {
    background-size: 346px auto;
    height: 666px;
  }
  @media screen and (max-width: 700px) {
    background-size: 246px auto;
    height: 466px;
  }
  @media screen and (max-width: 480px) {
    height: 366px;
  }
`
export const Presentation = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  border-radius: 0px 0px 0px 450px;
  background: linear-gradient(179.72deg, #010083 34.33%, #002fa6 99.76%);
  width: 100%;
  height: 90vh;
  max-height: 650px;
  min-height: 400px;
  margin: 0;
  padding: 0;
  .Phone {
    width: 50%;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    .Phone {
      width: 100%;
    }
  }
`

export const PresentationText = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  height: 100%;
  .link {
    text-decoration: none;
  }
  .Container {
    width: 80%;
  }
  .Title {
    margin: 0;
    font-family: RobotoBlack;
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 64px;
    letter-spacing: -0.555683px;
    color: #ffffff;
  }
  .Text {
    margin: 5px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 21.0939px;
    line-height: 27px;
    color: #bebebe;
  }
  @media screen and (max-width: 1100px) {
    .Container {
    }
    .Title {
      font-size: 40px;
      line-height: 44px;
    }
    .Text {
      font-size: 18px;
    }
  }
  @media screen and (max-width: 700px) {
    height: 80%;
    .Container {
      width: 90%;
    }
    .Title {
      font-size: 30px;
      line-height: 34px;
    }
    .Text {
      font-size: 12px;
      line-height: 17px;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    height: auto;
    .Container {
      padding-bottom: 0;
      margin-top: 10px;
    }
  }
  @media (orientation: landscape) {
    margin-top: 30px;
  }
`

export const Benefits = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
  margin-top: 100px;
  .title {
    font-family: RobotoBlack;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
    letter-spacing: -0.555683px;
    color: #ff7a00;
    margin-right: 15%;
  }
  .items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
  }
  @media screen and (max-width: 320px) {
    padding-top: 70px;
    .title {
      font-size: 40px;
      line-height: 52px;
    }
  }
  @media screen and (max-width: 500px) {
    .title {
      margin-right: 0;
    }
    margin-top: 0;
  }
  @media screen and (max-width: 750px) and (min-width: 450px) {
    padding-top: 0px;
    margin-top: 0px;
    height: auto;
    .title {
      font-size: 30px;
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 900px) and (min-width: 750px) {
    padding-top: 20px;
    margin-top: 80px;
  }
  @media screen and (max-width: 1024px) {
    .items {
      width: 100%;
    }
  }
  @media screen and (min-width: 1200px) {
    .title {
      margin-top: 20px;
    }
  }
  @media (orientation: landscape) and (max-width: 600px) {
    margin-top: 0px;
  }
`
export const CardItem = styled.div`
  height: 170px;
  width: 35%;
  margin: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .ico {
    padding: 15px;
    width: 100px;
    height: auto;
  }
  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(270deg, #fcc301 0%, #fbd601 100%);
    border-radius: 10px;
    margin-right: 15px;
    width: 100px;
    height: 100px;
    padding: 5px;
  }
  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .title {
    font-family: RobotoBlack;
    font-style: normal;
    font-weight: 400;
    font-weight: normal;
    font-size: 30px;
    line-height: 27px;
    color: #010083;
    margin: 0;
    margin-bottom: 12px;
    text-align: start;
  }
  .paragraph {
    font-family: RobotoBlack;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: #555555;
    margin: 0;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 5px;
  }
`

export const Container = styled.div`
  width: 100%;
  height: 200vh;
  max-height: 1100px;
  min-height: 1100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: ${(p: iphone) => (p.image ? `url(${p.image})` : '')};
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: auto;
  .title {
    font-family: RobotoBlack;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
    letter-spacing: -0.555683px;
    color: #010083;
    margin: 0;
    margin-bottom: 60px;
  }
  .title2 {
    font-family: RobotoBlack;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
    letter-spacing: -0.555683px;
    color: #010083;
    margin: 0;
    margin-top: 60px;
  }
  .cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    width: 80%;
  }
  .subtext {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    text-align: center;
    color: #2e2e2e;
    width: 340px;
    margin: 0;
  }
  @media screen and (max-width: 1200px) {
    .cards {
      width: 100%;
    }
  }
  @media screen and (max-width: 750px) {
    background-size: cover;
    margin-top: 100px;
    margin-bottom: 100px;
    .title {
      font-size: 30px;
      line-height: initial;
      margin-bottom: 30px;
    }
    .title2 {
      font-size: 30px;
      line-height: initial;
      margin-top: 30px;
    }
    .cards {
      justify-content: center;
      display: flex;
    }
  }
  @media screen and (max-width: 600px) {
    .title {
      font-size: 20px;
    }
    .title2 {
      font-size: 20px;
    }
    .subtext {
      width: 300px;
    }
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  margin: 10px;
  background-color: #ffff;
  width: 442px;
  height: 184px;
  .containerimg {
    display: flex;
    align-items: center;
  }
  .img {
    margin: 5px;
    margin-right: 10px;
  }
  .text {
    flex-direction: column;
    align-self: center;
  }
  .paragraph {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    color: #555555;
  }
  .subtitle {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 23px;
    color: #555555;
  }
  @media screen and (max-width: 950px) {
    width: 45%;
  }
  @media screen and (max-width: 750px) {
    width: 90%;
    .paragraph {
      font-size: 12px;
      line-height: 18px;
    }
  }
  @media screen and (max-width: 600px) {
    .img {
      width: 80px;
    }
  }
`
export const Carrousel = styled.div`
  width: 600px;
  .phrase {
    display: flex;
    flex-direction: row;
    text-align: center;
    padding: 40px;
    margin: 0;
  }
  .number {
    font-family: RobotoBlack;
    font-style: normal;
    font-weight: bold;
    font-size: 121px;
    line-height: 132px;
    text-align: center;
    letter-spacing: -2.30956px;
    color: #ffffff;
    margin: 0;
  }
  .oration {
    font-family: RobotoBlack;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 32px;
    letter-spacing: -0.555683px;
    color: #ffffff;
    margin: 0;
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    width: 300px;
    .number {
      font-size: 111px;
      margin: 2px;
    }
    .oration {
      font-size: 20px;
      line-height: 20px;
    }
  }
`
export const Tarjectview = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 800px;
  min-height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .link {
    text-decoration: none;
  }
  .text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 40%;
    margin: 5px;
  }
  .tarject {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;
    width: 50%;
  }
  .title {
    font-family: RobotoBlack;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 46px;
    letter-spacing: -0.555683px;
    color: #ff7a00;
    margin: 0;
  }
  .textDescription {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    color: #777777;
    margin: 0;
    padding: 10px;
  }
  @media screen and (max-width: 650px) {
    flex-direction: column;
    height: auto;
    .text {
      width: 100%;
    }
    .tarject {
      width: 100%;
    }
  }
`
export const Tarject = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 580px;
  width: 395px;
  margin: auto;
  background-image: ${(p: iphone) => (p.image ? `url(${p.image})` : '')};
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  border-radius: 30px;
  .name {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: bold;
    text-align: left;
    font-size: 32.6469px;
    letter-spacing: 0.204043px;
    color: rgba(255, 255, 255, 0.87);
  }
  .photo {
    width: 150px;
    height: 150px;
    overflow: hidden;
    margin: 0 auto;
    padding: 0;
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 50%;
  }
  .photoprofile {
    width: 100%;
  }
  .data {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 10px;
    margin-top: 80px;
  }
  .dataTitle {
    flex-direction: column;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 21.7646px;
    line-height: 18px;
    letter-spacing: 0.204043px;
    color: #112863;
    text-align: start;
    margin: 5px;
  }
  .dataDescription {
    flex-direction: column;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 19.044px;
    /* line-height: 18px; */
    letter-spacing: 0.340071px;
    color: #757575;
    text-align: start;
    margin: 5px;
  }
  @media screen and (max-width: 800px) {
    height: 444px;
    width: 295px;
    .photo {
      width: 120px;
      height: 140px;
    }
    .data {
      margin-top: 40px;
    }
  }
`
export const Mockups = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  height: 90vh;
  max-height: 500px;
  .playstore {
    width: 80%;
  }
  .img {
    width: 85%;
  }
  .mockup {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 33%;
    height: 100%;
  }
  .Message {
    font-family: RobotoBlack;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.555683px;
    color: #ff7a00;
  }
  @media screen and (max-width: 800px) {
    height: 60vh;
    .Message {
      font-size: 30px;
      line-height: 36px;
    }
  }
  @media screen and (max-width: 650px) {
    margin-top: 50px;
    margin-bottom: 30px;
    flex-direction: column;
    height: auto;
    max-height: 1500px;
    .mockup {
      width: 100%;
      margin: auto;
    }
    .img {
      width: 60%;
      margin-left: 40%;
    }
    .playstore {
      width: 60%;
    }
  }
`

export const Mockup = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin: auto;
  width: 33%;
  height: 100%;
  padding-top: 200px;
  background-image: ${(p: iphone) => (p.image ? `url(${p.image})` : '')};
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain;
  .alert {
    width: 70%;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    padding-bottom: 200px;
    .alert {
      width: 80%;
      margin: auto;
    }
  }
`

export const Form = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 600px;
  background-color: #002fa6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .text {
    font-family: RobotoBlack;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
    text-align: center;
    letter-spacing: -0.555683px;
    color: #ff7a00;
    margin: 5px;
    width: 50%;
  }
  .subtext {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    color: #ffffff;
    margin: 5px;
    width: 50%;
  }
  @media screen and (max-width: 950px) {
    .text {
      font-size: 30px;
      line-height: 30px;
      width: 80%;
    }
    .subtext {
      font-size: 15px;
      line-height: 18px;
      width: 80%;
      margin: 5px;
    }
  }
  @media screen and (max-width: 400px) {
    .text {
      font-size: 25px;
      width: 100%;
    }
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 200px;
  .logo {
    display: flex;
    flex-direction: column;
    width: 60px;
    margin: 0;
  }
  .logotext {
    margin: 0;
    text-align: center;
    font-family: MontserratMedium;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -1px;
    color: #1c387e;
    margin: auto;
  }
  .copy {
    display: flex;
    flex-direction: row;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    margin-top: 5px;
    border-top-style: solid;
    border-width: 1px;
  }
  .copyright {
    width: 60%;
    text-align: start;
    margin: 0;
  }
  .terms {
    width: 20%;
    text-align: start;
    text-decoration: none;
    margin: 0;
  }
  @media screen and (max-width: 600px) {
    .copy {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
    .copyright {
      width: 100%;
      text-align: center;
    }
    .terms {
      width: 100%;
      text-align: center;
    }
  }
`
