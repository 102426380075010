import React, { FC } from 'react'
import Router from './Router'
import store from 'lib/redux'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import theme from 'lib/theme'
import Persist from 'views/components/Persist'

const App: FC = () => (
  <Provider store={store}>
    <Persist loading={null}>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </Persist>
  </Provider>
)
export default App
/*
<StyledApp />
*/
