import styled from 'styled-components'

export const ContainerForm = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5px;
  width: 40%;
  .form {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .loader {
    margin: auto;
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #f5851f;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .text {
    font-family: RobotoBlack;
    font-style: normal;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.555683px;
    color: #ff7a00;
    margin: 5px;
    width: 100%;
  }
  @media screen and (max-width: 600px) {
    width: 70%;
  }
`

export const Input = styled.input`
  font-family: RobotoBlack;
  font-style: normal;
  font-weight: normal;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  width: 90%;
  padding: 12px;
  margin: 5px;
`
export const TextArea = styled.textarea`
  font-family: RobotoBlack;
  font-style: normal;
  font-weight: normal;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  width: 90%;
  height: 200px;
  padding: 12px;
  margin: 5px;
`

/*
background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.2);
box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.1);
border-radius: 8px;
*/
