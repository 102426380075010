import React, { useState } from 'react'
import { InputButton } from '../../UI/Buttons'
import { ContainerForm, Input, TextArea } from './ui'
import axios from 'axios'

const ContactAddForm = () => {
  const [isLoading, setLoading] = useState(false)
  const [respond, setRespond] = useState('')
  const Send = async (mail?: any, topic?: any, msg?: any) => {
    try {
      const { data: success } = await axios.post(`${process.env.REACT_APP_URL}/contacts/trigger`, {
        mail: mail,
        topic: topic,
        msg: msg,
      })
      return success
    } catch (e) {
      return { success: false }
    }
  }
  function SendEmail(event: any) {
    event.preventDefault()
    if (event.target.correo.value && event.target.asunto.value && event.target.mensaje.value) {
      setLoading(true)
      Send(event.target.correo.value, event.target.asunto.value, event.target.mensaje.value).then((data: any) => {
        if (data.success) {
          setRespond(`'Mensaje Enviado'`)
          setLoading(false)
        } else {
          setLoading(false)
          setRespond(`'Error al enviar el mensaje'`)
        }
      })
      event.target.reset()
    } else {
      alert('Complete todos los campos')
    }
  }
  return (
    <ContainerForm>
      <form onSubmit={SendEmail} className="form">
        <Input type="email" placeholder="Correo" name="correo" />
        <Input type="text" placeholder="Asunto" name="asunto" />
        <TextArea placeholder="Mensaje" name="mensaje"></TextArea>
        <div className="text">{respond}</div>
        {isLoading ? <div className="loader" /> : <InputButton type="submit" value="Enviar" />}
      </form>
    </ContainerForm>
  )
}

export default ContactAddForm
