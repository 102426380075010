import axios, { AxiosPromise } from 'axios'

const login = (email: string, password: string): AxiosPromise => {
  return axios.post(`${process.env.REACT_APP_MAIN_API}/api/login`, {
    email,
    password,
  })
}

export default login
