import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from 'views/screens/Home'

const MainRouter: FC = () => (
  <Switch>
    <Route path="/" exact={true}>
      <Home />
    </Route>
  </Switch>
)

export default MainRouter
