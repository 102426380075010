const theme: any = {
  colors: {
    main: '#440381',
    accent: '#D72638',
    error: '#ff0000',
    white: '#fff',
    black: '#000',
  },
}

export default theme
