import React, { FC } from 'react'
import Slider from 'infinite-react-carousel'
import {
  Container,
  Header,
  Presentation,
  Benefits,
  PresentationText,
  Phone,
  CardItem,
  Card,
  Carrousel,
  Tarjectview,
  Tarject,
  Mockups,
  Form,
  Footer,
  Mockup,
  ContainerHome,
} from './styled'
import { ButtonSimple } from 'views/components/UI/Buttons'
import Logo from '../../components/Assets/Logo.png'
import Camacol from '../../components/Assets/Camacol.png'
import Licify from '../../components/Assets/Licify.png'
import iphone from '../../components/Assets/iPhoneXFlying.png'
//items
import Register from '../../components/Assets/Register.png'
import Live from '../../components/Assets/Live.png'
import Learn from '../../components/Assets/Learn.png'
import Win from '../../components/Assets/Win.png'
//container
import Background from '../../components/Assets/Fondo.png'
import Mask from '../../components/Assets/Mask.png'
import Hands from '../../components/Assets/Hands.png'
import Distance from '../../components/Assets/Distance.png'
//tarjectview
import CardBack from '../../components/Assets/CardBack.png'
import Photo from '../../components/Assets/person.png'
//Mockups
import Mockup1 from '../../components/Assets/Mockup1.png'
import Mockup2 from '../../components/Assets/Mockup2.png'
import GooglePlay from '../../components/Assets/GooglePlay.png'
//Form
import ContactAddForm from '../../components/Forms/Contact'

const Home: FC = () => {
  const settings = { centerMode: false }
  function Scroll() {
    try {
      window.scroll({
        top: 4600,
        left: 0,
        behavior: 'smooth',
      })
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 4600)
    }
  }
  return (
    <ContainerHome>
      <Header>
        <div className="logo">
          <img src={Logo} alt="En tus manos" width="67" height="65px" />
          <p>
            En tus <br /> manos{' '}
          </p>
        </div>
        <div className="logoCompany">
          <img src={Camacol} alt="Camacol" height="57px" />
          <img src={Licify} alt="Licify" height="40px" />
        </div>
      </Header>
      <Presentation>
        <PresentationText>
          <div className="Container">
            <p className="Title">
              ¿Cómo cuidar a tu empresa durante <br />
              la pandemia?{' '}
            </p>
            <p className="Text">
              Con la nueva aplicación En Tus Manos <br /> puedes lograrlo y mucho más...
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.entusmanos&hl=es_CO"
              className="link"
            >
              <ButtonSimple>¡Descargar ya!</ButtonSimple>
            </a>
            <ButtonSimple onClick={Scroll}> Contáctanos </ButtonSimple>
          </div>
        </PresentationText>
        <div className="Phone">
          <Phone image={iphone}></Phone>
        </div>
      </Presentation>
      <Benefits>
        <div className="title">Conoce nuestros beneficios...</div>
        <div className="items">
          <CardItem>
            <div className="image">
              <img src={Register} className="ico" alt="Registra" />
            </div>
            <div className="text">
              <p className="title">Registra</p>
              <p className="paragraph">
                Recibe un registro diario del estado de salud de todos los empleados de tu empresa.
              </p>
            </div>
          </CardItem>
          <CardItem>
            <div className="image">
              <img src={Learn} className="ico" alt="Aprende" />
            </div>
            <div className="text">
              <p className="title">Aprende</p>
              <p className="paragraph">Descubre todo el contenido sobre el COVID-19 y los protocolos de seguridad.</p>
            </div>
          </CardItem>
          <CardItem>
            <div className="image">
              <img src={Win} className="ico" alt="Gana" />
            </div>
            <div className="text">
              <p className="title">Gana</p>
              <p className="paragraph">
                Genera puntos haciendo encuestas sobre el contenido aprendido en la aplicación.
              </p>
            </div>
          </CardItem>
          <CardItem>
            <div className="image">
              <img src={Live} className="ico" alt="Vive" />
            </div>
            <div className="text">
              <p className="title">¡Vive!</p>
              <p className="paragraph">
                Ayuda a Colombia salvar vidas, generando conciencia sobre la pandemia y cómo deternerla.
              </p>
            </div>
          </CardItem>
        </div>
      </Benefits>
      <Container image={Background}>
        <p className="title">
          ¡Estamos a tiempo de reducir <br />
          el contagio!
        </p>
        <div className="cards">
          <Card>
            <div className="containerimg">
              <img className="img" src={Mask} alt="mascara" />
            </div>
            <div className="text">
              <p className="paragraph">
                El uso correcto de tapabocas ha reducido considerablemente el contagio en hasta 4 veces
              </p>
              <p className="subtitle">@CancerWarrior</p>
            </div>
          </Card>
          <Card>
            <div className="containerimg">
              <img className="img" src={Distance} alt="distancia" />
            </div>
            <div className="text">
              <p className="paragraph">
                Distanciamiento social aplana considerablemente la curva y reduce el # de casos hasta 4 veces en el
                pico.
              </p>
              <p className="subtitle">Secretaría de Gobierno de Bogotá </p>
            </div>
          </Card>
          <Card>
            <div className="containerimg">
              <img className="img" src={Hands} alt="manos" />
            </div>
            <div className="text">
              <p className="paragraph">
                Lavarnos frecuentemente las manos, reduce hasta un 50% los casos de las infecciones respiratorias,
                incluyendo el Coronavirus COVID-19.
              </p>
              <p className="subtitle">COVID-19 Research</p>
            </div>
          </Card>
        </div>
        <p className="title2">¡Tenemos 7 grandes retos!</p>
        <p className="subtext">
          ¡Camacol y Licify unimos fuerzas para enfrentarlos y ayudarte a cumplir las medidas de bioseguridad,
          apalancados en la tecnología!
        </p>
        <Carrousel>
          <Slider {...settings}>
            <div>
              <div className="phrase">
                <p className="number">1</p>
                <p className="oration">¿Cómo difundir un mensaje a tantas personas sin poderlas reunir?</p>
              </div>
            </div>
            <div>
              <div className="phrase">
                <p className="number">2</p>
                <p className="oration">
                  ¿Cómo hacerlo llegar a los contratistas y terceros que no son empleados directos?
                </p>
              </div>
            </div>
            <div>
              <div className="phrase">
                <p className="number">3</p>
                <p className="oration">¿Cómo asegurar que llegue el mensaje correcto y evitar el teléfono roto?</p>
              </div>
            </div>
            <div>
              <div className="phrase">
                <p className="number">4</p>
                <p className="oration">¿Cómo garantizar que el mensaje sea entendido y genere un hábito?</p>
              </div>
            </div>
            <div>
              <div className="phrase">
                <p className="number">5</p>
                <p className="oration">¿Qué hacer para organizar turnos de operación y evitar aglomeraciones?</p>
              </div>
            </div>
            <div>
              <div className="phrase">
                <p className="number">6</p>
                <p className="oration">
                  ¿Cómo generar un canal de doble vía para recibir información oportuna en tiempo real?
                </p>
              </div>
            </div>
            <div>
              <div className="phrase">
                <p className="number">7</p>
                <p className="oration">
                  ¿Cómo analizar toda esta información y procesarla para tomar acciones inmediatas?
                </p>
              </div>
            </div>
          </Slider>
        </Carrousel>
      </Container>
      <Tarjectview>
        <div className="text">
          <p className="title">¿Ya tienes una base de datos de tus empleados?</p>
          <p className="textDescription">
            ¡Descarga nuestros documentos, llena los datos y listo! Ellos tendrán información lista para comenzar a usar
            la aplicación.
          </p>
          <a href={`${process.env.REACT_APP_FILE}`} download="BasedeDatos" className="link">
            <ButtonSimple>¡Descargar ya!</ButtonSimple>
          </a>
        </div>
        <div className="tarject">
          <Tarject image={CardBack}>
            <p className="name">
              Albeiro <br />
              Gómez Tobón
            </p>
            <div className="photo">
              <img src={Photo} className="photoprofile" alt="perfil" />
            </div>
            <div className="data">
              <div className="dataTitle">
                <p>Cédula</p>
                <p>Empleador</p>
                <p>Proyecto</p>
              </div>
              <div className="dataDescription">
                <p>1020304059</p>
                <p>Carlos López</p>
                <p>Acuarela</p>
              </div>
            </div>
          </Tarject>
        </div>
      </Tarjectview>
      <Mockups>
        <Mockup image={Mockup1} />
        <div className="mockup">
          <p className="Message">
            En Tus Manos, <br />
            encuéntranos en:
          </p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.entusmanos&hl=es_CO"
            className="link"
          >
            <img src={GooglePlay} className="playstore" alt="GooglePlay" />
          </a>
        </div>
        <div className="mockup">
          <img src={Mockup2} className="img" alt="Sintomas" />
        </div>
      </Mockups>
      <Form>
        <p className="text">
          Ahora puedes tener En Tus Manos,
          <br /> en tu empresa
        </p>
        <p className="subtext">Llena los siguientes datos y nos pondremos en contacto contigo lo antes posible.</p>
        <ContactAddForm />
      </Form>
      <Footer>
        <div className="logo">
          <a href="https://play.google.com/store/apps/details?id=com.entusmanos&hl=es_CO">
            <img src={Logo} alt="En tus manos" width="67" height="65px" />
          </a>
          <p className="logotext">
            En tus <br /> manos{' '}
          </p>
        </div>
        <div className="copy">
          <p className="copyright"></p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://licify.co/terminos-y-condiciones/"
            className="terms"
          >
            Términos y condiciones
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://licify.co/en-tus-manos-politica-de-privacidad/"
            className="terms"
          >
            Política de privacidad
          </a>
        </div>
      </Footer>
    </ContainerHome>
  )
}

export default Home
