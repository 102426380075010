/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import persist from 'ducks/persist/reducer'
import { combineReducers } from 'redux-immutable'
import userReducer from 'ducks/user/reducer'

const appReducer = combineReducers({
  userReducer,
  _persist: persist,
})

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action)
}

export default rootReducer
