import styled from 'styled-components'
import { color, position, space, layout, LayoutProps, SpaceProps, ColorProps, PositionProps } from 'styled-system'
import { Link } from 'react-router-dom'

export type BlockProps = LayoutProps & SpaceProps & ColorProps & PositionProps

export const Button = styled.div<BlockProps>`
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.main};
  color: #fff;
  padding: 1rem;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${layout}
  ${space}
  ${color}
  ${position}
`

export const ButtonSimple = styled.div`
  font-family: MontserratMedium;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #212f80;
  width: 257px;
  height: 48px;
  background: #ffdf00;
  margin: 15px;
  box-shadow: 0px 2px 2px rgba(181, 181, 181, 0.5);
  border-radius: 100px;
  text-decoration: none;
  cursor: pointer;
  .link {
    text-decoration: none;
  }
  @media screen and (max-width: 1100px) {
    width: 157px;
    font-size: 12px;
  }
`
export const InputButton = styled.input`
  align-self: center;
  font-family: MontserratMedium;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #212f80;
  width: 257px;
  height: 48px;
  background: #ffdf00;
  margin: 10px;
  box-shadow: 0px 2px 2px rgba(181, 181, 181, 0.5);
  border-radius: 100px;
  text-decoration: none;
  cursor: pointer;
  .link {
    text-decoration: none;
  }
  @media screen and (max-width: 1100px) {
    width: 157px;
    font-size: 12px;
  }
`

export const LinkButton = styled(Link)<BlockProps>`
  color: ${(props) => props.theme.colors.main};
  padding: 1rem;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${layout}
  ${space}
  ${color}
  ${position}
`
